import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Front Squat 4×8\\@70% 1RM`}</p>
    <p>{`Ball Hamstring Curls 4×8`}</p>
    <p>{`then,`}</p>
    <p>{`21-Thrusters (95/65)`}</p>
    <p>{`42-Double Unders`}</p>
    <p>{`21-Wall Balls (20/14)`}</p>
    <p>{`42-Double Unders`}</p>
    <p>{`15-Thrusters`}</p>
    <p>{`30-Double Unders`}</p>
    <p>{`15-Wall Balls`}</p>
    <p>{`30-Double Unders`}</p>
    <p>{`9-Thrusters`}</p>
    <p>{`18-Double Unders`}</p>
    <p>{`9-Wall Balls`}</p>
    <p>{`18-Double Unders`}</p>
    <p>{`For time.`}</p>
    <p><em parentName="p">{`*`}{`compare to 7/14/20`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      